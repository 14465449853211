import { Link } from "gatsby"
import React from "react"
import Logo from "./Logo"
import SocialButtons from "./SocialButtons"

const Svg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    preserveAspectRatio="none"
    viewBox="0 0 1286 300"
  >
    <path
      fill="#28b5f5"
      d="M299.24 33.56C408.74 42.45 564.1 83.87 730.91 89.84C997.52 99.38 1185.21 85.62 1294 48.54C1294 56.37 1294 95.53 1294 166L0 166L0 79.79C103.53 41.17 203.28 25.76 299.24 33.56Z"
    />
    <path
      fill="#29b6f6"
      d="M260.96 48.89C333.12 61.07 512.47 93.98 647.51 99.14C911.65 109.24 1127.14 80.86 1294 14C1294 24.13 1294 74.8 1294 166L0 166L0 48.89C83.8 33.66 170.79 33.66 260.96 48.89Z"
    />
    <path
      stroke="#00417c"
      strokeWidth="6px"
      fillOpacity="0"
      d="M0 47.92C68.84 37.01 145.44 36.11 229.8 45.25C356.34 58.95 593.03 118.95 869.66 98.96C1054.08 85.64 1195.53 57.32 1294 14"
    />
    <path
      fill="#047abf"
      d="M250.88 58.68C387.39 66.25 648 157 879.89 157C1037.2 157 1175.23 133.78 1294 87.35C1294 101.52 1294 172.41 1294 300L0 300L0 133.01C104.57 79.98 188.19 55.2 250.88 58.68Z"
    />
  </svg>
)
export default ({ additionalClassName = "" }) => {
  return (
    <footer className={additionalClassName}>
      <div className="svg-container">{Svg}</div>
      <div className="content">
        <div className="layout-container">
          <div className="content-container">
            <div className="logo-items-container">
              <a className="logo" href="/">
                <Logo />
              </a>
              <p className="slogan">Helping small businesses make big waves</p>
            </div>
            <div className="links-container">
              <div>
                <Link to="/project-showcase/">ALL PROJECTS</Link>
              </div>
              <div>
                <a href="https://portal.wavefoundry.io">CLIENT PORTAL</a>
              </div>
            </div>
            <div className="social-btns-container flex-center-center">
              <SocialButtons />
            </div>
          </div>
          <p className="legal">
            © COPYRIGHT {new Date().getFullYear()} WAVEFOUNDRY, LLC
          </p>
        </div>
      </div>
    </footer>
  )
}
