import React from "react"

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 800 90"
      preserveAspectRatio="none"
      fill="currentColor"
    >
      <path d="M0 90C0 44.5 0 19.23 0 14.17C0 14.17 0 14.17 0 14.17C81.75 34.45 167.07 35.68 249.37 17.76C269.95 13.28 227.25 22.58 252.99 16.97C318.39 2.73 386.13 3.07 451.39 17.95C480.2 24.53 470.46 22.3 500 29.04C562.76 43.36 628 42.73 690.48 27.21C705.08 23.58 741.59 14.51 800 0C800 6 800 36 800 90L0 90Z" />
    </svg>
  )
}
